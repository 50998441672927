@import url("https://fonts.googleapis.com/css?family=Roboto:300");
@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick-theme.css";

body {
  font-family: "Roboto", sans-serif;
}

a,
a:hover {
  text-decoration: none;
  color: #000;
}

.fullWidth {
  width: 100%;
}

body,
#root,
.cont {
  min-height: 100vh;
}

.headerTop {
  background-color: #eeeeee;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}

.headerTopRight {
  text-align: right;
}
.smallText {
  font-size: 0.8em;
  padding-right: 10px;
}

.mainHeader,
.mainHeaderLeft,
.mainHeaderRight {
  padding: 5px 0;
}

.mainHeaderRight {
  margin-top: 20px;
}

.mainHeaderLeft {
  margin-top: 30px;
}

.mainHeader {
  text-align: center;
}

.mainHeaderRight table tr td {
  text-align: center;
  font-size: 0.6em;
  padding: 0 3px;
}

.mainHeaderLeft table tr td {
  text-align: center;
  font-size: 0.8em;
  padding: 0 3px;
}

.mainHeaderRight table tr td:hover {
  border-bottom: 1px #111 solid;
  cursor: pointer;
}

.mainHeaderLeft table tr td:hover {
  border-bottom: 1px #111 solid;
  cursor: pointer;
}

.detailsContent {
  margin-top: 20px;
  position: fixed;
  right: 100px;
  width: auto;
  padding: 10px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.detailsContent h3 {
  text-transform: uppercase;
}

.detailsContent h4 {
  text-transform: uppercase;
  margin-top: 5px;
}

.detailsContent a:hover {
  cursor: pointer;
  border-bottom: 1px solid #000;
}

.hidden {
  display: none;
}

.visible {
  z-index: 1000;
  background: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-top: 1px #cccccc solid;
  border-bottom: 1px #cccccc solid;
}

.carousel {
  background-color: #ffffff;
  padding: 20px;
}

.mainCarouselImage {
  width: 95%;
  max-height: 350px;
  max-width: 350px;
}

.sliderText {
  right: 50%;
  left: 50%;
  position: absolute;
  color: red;
  text-align: center;
}

.productSlider {
  margin-bottom: 20px;
  max-width: 350px;
}

.addAmount {
  width: 50px;
}
.centerImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.margin {
  margin-top: 20px;
}

.footer {
  margin-top: 10px;
  background-color: #eeeeee;
  border-top: 1px solid #dddddd;
  width: 100%;
}

.capitalize {
  text-transform: uppercase;
}

.CategoryMainDesctiption h1,
h2 {
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 0px;
}
.CategoryMainDesctiption h3 {
  text-transform: capitalize;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.subCardTitle {
  text-transform: uppercase;
}

.subCardText {
}

.deleteFromBasket:hover,
.changeAmount:hover {
  cursor: pointer;
}

.left {
  text-align: left;
}

.noStyle td,
td:hover {
  border-bottom: 0px #000 solid;
  cursor: default;
}

.input-group-prepend input,
.input-group-prepend button,
.btn {
  border-radius: 0;
}

.navigationLink a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.noBottom {
  margin-bottom: 0;
}

.sliderCard {
  max-height: 250px;
  max-width: 100%;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
}

.slick-initialized .slick-track {
  display: flex;
  align-items: center;
}
.slick-slide {
  margin: 10px;
}

.headerMain {
  border-bottom: 1px solid #ccc;
}

.headerIcon:hover {
  text-decoration: underline;
}

.headerIcon {
  padding: 0 10px;
}
.slick-prev:before,
.slick-next:before {
  color: #000000;
}
@media only screen and (max-width: 768px) {
  .menuLarge {
    display: none;
  }
}

.headerMessage:hover {
  cursor: pointer;
}

@media only screen and (min-width: 769px) and (max-width: 3000px) {
  .menuSmall {
    display: none;
  }
}
